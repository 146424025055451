/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

import { FieldError } from 'react-hook-form';
import { Container, Label, Input, LabelError } from './styles';

interface InputDatePickerProps {
  label: string;
  name: string;
  error?: FieldError;
}

type Ref = HTMLInputElement;

const InputDatePicker = forwardRef<Ref, InputDatePickerProps>(
  ({ label, name, error, ...props }, ref) => (
    <Container>
      <Label>{label}</Label>
      <Input innerRef={ref} name={name} {...props} />
      <LabelError>
        {error?.message}
        &nbsp;
      </LabelError>
    </Container>
  ),
);

export default InputDatePicker;
