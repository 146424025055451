import React, { useCallback, useState } from 'react';

import { TextField } from '@material-ui/core';

interface InputTextDebounceProps {
  label: string;
  name: string;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  handleOnChange: any;
  error?: boolean;
  helperText?: string;
  onChange?: any;
  InputProps?: any;
}

const InputTextDebounce: React.FC<InputTextDebounceProps> = ({
  label,
  name,
  fullWidth,
  margin,
  error,
  helperText,
  handleOnChange,
  InputProps,
  onChange,
  ...rest
}) => {
  const [timer, setTimer] = useState<number | null>(null);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        handleOnChange(value);
      }, 1000);
      setTimer(newTimer);
      if (onChange) {
        onChange(value);
      }
    },
    [handleOnChange, onChange, timer],
  );

  return (
    <TextField
      {...rest}
      label={label}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      InputProps={InputProps}
    />
  );
};

export default InputTextDebounce;
