import React from 'react';
import { Map } from 'immutable';
import MaskedInput from 'react-text-mask';

export interface InputProps {
  name: string;
  defaultValue?: string | number;
  type?: 'text' | 'password' | 'number';
  mask: 'cpf' | 'cep' | 'cnpj' | 'decimals';
  error?: boolean;
  inputRef?: any;
}

const masks = Map({
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  decimals: [/\d/, ',', /\d/, /\d/],
});

type Ref = HTMLInputElement;

const InputMask: React.FC<InputProps> = ({
  name,
  defaultValue,
  type = 'text',
  mask,
  inputRef,
  ...rest
}) => {
  return (
    <MaskedInput
      {...rest}
      type={type}
      name={name}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      defaultValue={defaultValue}
      mask={masks.get(mask)!}
    />
  );
};

export default React.memo(InputMask);
