import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';

import { Container, Label, Input, InvalidFeedback } from './styles';

export interface InputMoneyProps {
  name: string;
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  width?: string;
  height?: string;
  type?: 'text';
  value: number;
  onChange: (value: number) => void;
  handleOnChange?: (value: number) => void;
  onBlur?: any;
  handleOnBlur?: any;
  validateOnBlur?: boolean;
}

type Ref = HTMLInputElement;

const InputMoney = forwardRef<Ref, InputMoneyProps>(
  (
    {
      label = '',
      name,
      placeholder = '',
      error,
      defaultValue,
      width,
      height,
      type = 'text',
      value,
      onChange,
      handleOnChange,
      onBlur,
      handleOnBlur,
      validateOnBlur,
      ...rest
    },
    ref,
  ) => {
    const [maskedValue, setMaskedValue] = useState<string>(String(value));

    const formatCurrency = (newValue: number) => {
      const formatter = new global.Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(Number(newValue));
    };

    const normalizeValue = useCallback((number: string) => {
      return Number(number.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;
    }, []);

    const calculateValues = useCallback(
      (inputFieldValue: string): [number, string] => {
        // Normalizo o valor do input
        const valueNormalize = normalizeValue(inputFieldValue);

        const formattedValue = formatCurrency(valueNormalize);

        return [valueNormalize, formattedValue];
      },
      [normalizeValue],
    );

    const updateValues = useCallback(
      (currentValue: string): [number, string] => {
        const [calculatedValue, calculatedMaskedValue] = calculateValues(
          currentValue,
        );
        setMaskedValue(calculatedMaskedValue);
        onChange(calculatedValue);
        if (handleOnChange !== undefined) {
          handleOnChange(calculatedValue);
        }

        return [calculatedValue, calculatedMaskedValue];
      },
      [calculateValues, handleOnChange, onChange],
    );

    const handleBlur = useCallback(
      (val) => {
        if (!validateOnBlur) return;
        if (onBlur !== undefined) {
          onBlur(val);
        }
        const currentValue = normalizeValue(maskedValue);

        if (currentValue === value) return;

        const newValue = formatCurrency(value);

        updateValues(newValue);
      },
      [
        maskedValue,
        normalizeValue,
        onBlur,
        updateValues,
        validateOnBlur,
        value,
      ],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        updateValues(event.target.value);
      },
      [updateValues],
    );

    useEffect(() => {
      const currentValue = formatCurrency(value);

      // Aplico desestruturação e pego o 2º parâmetro que contém o valor atual mascarado
      const [, currentMaskedValue] = calculateValues(currentValue);

      // Atualizo o estado da máscara
      setMaskedValue(currentMaskedValue);
    }, [value, calculateValues]);

    return (
      <Container style={{ width, height }}>
        <Label>
          {label}
          &nbsp;
        </Label>
        <Input
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={maskedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          {...rest}
        />
        <InvalidFeedback>
          {error?.message}
          &nbsp;
        </InvalidFeedback>
      </Container>
    );
  },
);

export default React.memo(InputMoney);
