import React, { useCallback } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

type OptionValue = {
  label: string;
  value: string | number;
};

interface InputMultiSelectProps {
  fullWidth?: boolean;
  options: OptionValue[];
  valueDefault: OptionValue[];
  onChange?: any;
  error?: boolean;
  errorMessage?: string;
  name: string;
  label: string;
}

const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  options,
  fullWidth = false,
  valueDefault,
  onChange,
  error,
  errorMessage,
  name,
  label,
  ...rest
}) => {
  const handleChange = useCallback(
    (_, newValue) => {
      onChange(newValue);
    },
    [onChange],
  );
  return (
    <Autocomplete
      {...rest}
      multiple
      options={options}
      defaultValue={valueDefault}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          name={name}
          label={label}
          error={error}
          helperText={errorMessage}
        />
      )}
    />
  );
};

export default InputMultiSelect;
