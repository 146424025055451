import styled from 'styled-components';
import { lighten } from 'polished';
import { metrics } from '@styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

export const Label = styled.label`
  display: inline-block;
  height: 33px;
  font-weight: 600;
  color: ${(props) => props.theme.text.colors.dark};
`;

export const Input = styled.input`
  border: ${({ theme }) =>
    `1px solid ${lighten(0.3, theme.text.colors.muted)}`};
  border-radius: 4px;
  padding: 0 7px;
  height: 38px;
  @media only screen and (min-width: ${metrics.laptop}) {
    font-size: 14px;
  }
`;

export const InvalidFeedback = styled.span`
  font-size: 14px;
  padding-left: 5px;
  height: 33px;
  color: ${({ theme }) => theme.text.colors.danger};
`;
