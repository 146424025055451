import React, { useEffect, useState } from 'react';
import TemplateScreen from '@components/template-screen';

import { Controller, useForm } from 'react-hook-form';

import { TextField } from '@material-ui/core';
import { ButtonLoaderMaterial } from '@components/buttons';
import { Text } from '@components/texts';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import * as yup from 'yup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { formatNumberEnToBr } from '@utils/format-number-en-to-br';
import { formatNumberBrToEn } from '@utils/format-number-br-to-us';
import CurrencyInput from 'react-currency-input';
import { InputMultiSelect, InputSelectSearch } from '@components/inputs';
import api from '../../../services/api';
import { Container, Content, Form, ButtonWrapper } from './styles';

const validationSchema = yup.object().shape({
  inicioVigencia: yup
    .date()
    .required('Campo Requerido')
    .typeError('Data inválida'),
  fimVigencia: yup
    .date()
    .required('Campo Requerido')
    .typeError('Data inválida'),
  id_linha: yup.array().min(1, 'Campo requerido'),
});

const initialvalues = {
  id_convenio_grupo: '',
  id_linha: [],
  taxaInicial: '',
  taxaFinal: '',
  prazoInicial: '',
  prazoFinal: '',
  remuneracaoTaxa1: '',
  remuneracaoTaxa2: '',
  inicioVigencia: null,
  fimVigencia: null,
  tiquete: '',
  segmento: '',
};

interface TaxasProps {
  id_convenio_grupo: string;
  taxaInicial: string;
  taxaFinal: string;
  prazoInicial: string;
  prazoFinal: string;
  remuneracaoTaxa1: string;
  remuneracaoTaxa2: string;
  inicioVigencia: string | null;
  fimVigencia: string | null;
  id_linha: Array<{ label: string; value: number }>;
  tiquete: string;
  segmento: string;
}

const CadastroEmpresaCDC: React.FC = () => {
  const { errors, control, reset, handleSubmit } = useForm({
    validationSchema,
    defaultValues: initialvalues,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const history = useHistory();
  const [linhasOptions, setLinhasOptions] = useState([]);
  const [conveniosGruposOptions, setConveniosGruposOptions] = useState([]);

  useEffect(() => {
    setIsEditMode(!!urlParams.get('id'));

    const loadDataTaxa = async () => {
      if (urlParams.get('id')) {
        const id = urlParams.get('id');

        await api.get(`/cdc-taxas-emp/loadById?id=${id}`).then((response) => {
          const inicioVigencia = response.data.inicio_vigencia;
          const fimVigencia = response.data.fim_vigencia;

          reset({
            id_convenio_grupo: response.data.id_convenio_grupo_nome,
            id_linha: response.data.cdc_taxas_emp_linhas.map((linha) => ({
              value: linha.linhas.id,
              label: `${linha.linhas.linha} - ${linha.linhas.descricao}`,
            })),
            taxaInicial: formatNumberEnToBr(response.data.tx_inicial),
            taxaFinal: formatNumberEnToBr(response.data.tx_final),
            prazoInicial: response.data.prazo_inicio,
            prazoFinal: response.data.prazo_fim,
            remuneracaoTaxa1: formatNumberEnToBr(response.data.rem_taxa_1),
            remuneracaoTaxa2: formatNumberEnToBr(response.data.rem_taxa_2),
            tiquete: formatNumberEnToBr(response.data.tiquete),
            inicioVigencia,
            fimVigencia,
            segmento: response.data.segmento,
          });
        });
      }
    };
    loadDataTaxa();
  }, []);

  useEffect(() => {
    const getLinhasGerente = async () => {
      try {
        const response = await api.get('/linhas/loadAll');
        const data = response.data.map((linha) => ({
          label: `${linha.linha} - ${linha.descricao}`,
          value: linha.id,
        }));
        setLinhasOptions(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching GCPF options:', error);
      }
    };
    getLinhasGerente();
  }, []);

  useEffect(() => {
    const getConveniosGruposOptions = async () => {
      try {
        const response = await api.get('/convenios-grupos/loadAll');
        const data = response.data.map((convenio) => ({
          label: convenio.nome,
          value: convenio.id,
        }));
        setConveniosGruposOptions(data);
      } catch {
        toast.error(`Erro ao carregar os convênios grupos`);
      }
    };
    getConveniosGruposOptions();
  }, []);

  const onSubmit = async (data: TaxasProps) => {
    try {
      setIsloading(true);

      const inicioVigencia = format(
        new Date(data.inicioVigencia!),
        'yyyy-MM-dd',
      );
      const fimVigencia = format(new Date(data.fimVigencia!), 'yyyy-MM-dd');

      if (isEditMode) {
        await api.put('/cdc-taxas-emp/update', {
          id: urlParams.get('id'),
          id_convenio_grupo_nome: data.id_convenio_grupo,
          id_linha: data.id_linha.map((linha) => linha.value),
          taxaInicial: formatNumberBrToEn(data.taxaInicial),
          taxaFinal: formatNumberBrToEn(data.taxaFinal),
          prazoInicial: data.prazoInicial,
          prazoFinal: data.prazoFinal,
          remuneracaoTaxa1: formatNumberBrToEn(data.remuneracaoTaxa1),
          remuneracaoTaxa2: formatNumberBrToEn(data.remuneracaoTaxa2),
          inicioVigencia,
          fimVigencia,
          tiquete:
            formatNumberBrToEn(data.tiquete) === 'null'
              ? 0
              : formatNumberBrToEn(data.tiquete),
          segmento: data.segmento,
        });

        toast.success('Taxa atualizada com sucesso!');
      } else {
        await api.post('/cdc-taxas-emp/create', {
          id_convenio_grupo_nome: data.id_convenio_grupo,
          id_linha: data.id_linha.map((linha) => linha.value),
          taxaInicial: formatNumberBrToEn(data.taxaInicial),
          taxaFinal: formatNumberBrToEn(data.taxaFinal),
          prazoInicial: data.prazoInicial,
          prazoFinal: data.prazoFinal,
          remuneracaoTaxa1: formatNumberBrToEn(data.remuneracaoTaxa1),
          remuneracaoTaxa2: formatNumberBrToEn(data.remuneracaoTaxa2),
          inicioVigencia,
          fimVigencia,
          tiquete:
            formatNumberBrToEn(data.tiquete) === 'null'
              ? 0
              : formatNumberBrToEn(data.tiquete),
          segmento: data.segmento,
        });

        toast.success('Taxa cadastrada com sucesso!');
      }

      history.goBack();
    } catch (e) {
      const error = e as any;
      toast.error(`${error.response.data.message}`);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      <TemplateScreen onBack title="Cadastro remuneração empresa CDC">
        <Container>
          <Content>
            <Text fontSize="1.1rem">Dados cadastrais</Text>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <>
                <Controller
                  as={InputSelectSearch}
                  options={conveniosGruposOptions}
                  error={!!errors.id_convenio_grupo}
                  helperText={errors.id_convenio_grupo?.message as string}
                  label="Convênio Grupo"
                  fullWidth
                  defaultValue={[]}
                  control={control}
                  name="id_convenio_grupo"
                  margin="normal"
                />

                <Controller
                  as={InputMultiSelect}
                  options={linhasOptions}
                  error={!!errors.id_linha}
                  errorMessage={errors.id_linha ? 'Campo requerido' : ''}
                  label="Número Linha"
                  fullWidth
                  valueDefault={initialvalues.id_linha}
                  control={control}
                  name="id_linha"
                />
                <Controller
                  as={InputSelectSearch}
                  control={control}
                  error={!!errors.segmento}
                  helperText={errors.segmento?.message as string}
                  name="segmento"
                  label="Segmento Convênio"
                  options={[
                    {
                      value: '1',
                      label: 'PUBLICO',
                    },
                    {
                      value: '2',
                      label: 'PRIVADO',
                    },
                  ]}
                />
                <Controller
                  as={TextField}
                  focused
                  control={control}
                  error={!!errors.tiquete}
                  name="tiquete"
                  label="Tiquete"
                  placeholder="Insira o tiquete"
                  fullWidth
                  margin="normal"
                  style={{ marginRight: '50px' }}
                  InputProps={{
                    inputComponent: CurrencyInput as any,
                    inputProps: {
                      decimalSeparator: ',',
                      thousandSeparator: '.',
                      precision: 2,
                    },
                  }}
                />

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.taxaInicial}
                    name="taxaInicial"
                    label="Taxa inicial"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.taxaFinal}
                    name="taxaFinal"
                    label="Taxa Final"
                    placeholder="Insira a taxa final"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    control={control}
                    error={!!errors.prazoInicial}
                    name="prazoInicial"
                    label="Prazo inicial"
                    placeholder="Insira o prazo inicial"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                  />
                  <Controller
                    as={TextField}
                    control={control}
                    error={!!errors.prazoFinal}
                    name="prazoFinal"
                    label="Prazo final"
                    placeholder="Insira o prazo final"
                    fullWidth
                    margin="normal"
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.remuneracaoTaxa1}
                    name="remuneracaoTaxa1"
                    label="Remuneracao Taxa 1"
                    placeholder="Insira Taxa 1"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.remuneracaoTaxa2}
                    name="remuneracaoTaxa2"
                    label="Remuneracao Taxa 2"
                    placeholder="Insira Taxa 2"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={KeyboardDatePicker}
                    control={control}
                    name="inicioVigencia"
                    label="Inicio vigência"
                    fullWidth
                    size="small"
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={{}}
                    invalidDateMessage="Formato de data inválida"
                    error={!!errors.inicioVigencia}
                    helperText={errors.inicioVigencia?.message}
                    style={{ marginRight: '50px' }}
                  />
                  <Controller
                    as={KeyboardDatePicker}
                    control={control}
                    name="fimVigencia"
                    label="Fim vigência"
                    fullWidth
                    size="small"
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={{}}
                    invalidDateMessage="Formato de data inválida"
                    error={!!errors.fimVigencia}
                    helperText={errors.fimVigencia?.message}
                  />
                </div>
              </>
              <ButtonWrapper>
                <ButtonLoaderMaterial
                  type="submit"
                  label="Salvar"
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                />
              </ButtonWrapper>
            </Form>
          </Content>
        </Container>
      </TemplateScreen>
    </>
  );
};

export default CadastroEmpresaCDC;
