import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import { Container, Label, Input, InvalidFeedback } from './styles';

export interface InputProps {
  name: string;
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  width?: string;
  height?: string;
  widthInput?: string;
  heightInput?: string;
  type?: 'text' | 'password' | 'number';
  maxLength?: number;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
}

type Ref = HTMLInputElement;

const InputText = forwardRef<Ref, InputProps>(
  (
    {
      label = '',
      name,
      placeholder = '',
      error,
      defaultValue,
      width,
      height,
      type = 'text',
      maxLength,
      widthInput,
      heightInput,
      onChange,
      onBlur,
      disabled = false,
    },
    ref,
  ) => {
    return (
      <Container style={{ width, height }}>
        <Label>
          {label}
          &nbsp;
        </Label>

        <Input
          type={type}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          ref={ref}
          maxLength={maxLength}
          max={maxLength}
          disabled={disabled}
          widthInput={widthInput}
          heightInput={heightInput}
          onChange={onChange}
          onBlur={onBlur}
        />
        <InvalidFeedback>
          {error?.message}
          &nbsp;
        </InvalidFeedback>
      </Container>
    );
  },
);

export default React.memo(InputText);
