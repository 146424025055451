import React, { useCallback } from 'react';
import TemplateScreen from '@components/template-screen';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import GridAgenteCDC from './grid';

const AgenteCDC: React.FC = () => {
  const history = useHistory();

  const { control } = useForm();

  const registerRemuneracao = useCallback(() => {
    history.push('/taxas/cadastro-remuneracao/agente-cdc');
  }, [history]);

  return (
    <TemplateScreen
      onClick={registerRemuneracao}
      title="Remuneração CDC - Agente"
    >
      <div style={{ display: 'flex', alignItems: 'flex-end', padding: '20px' }}>
        <Controller
          as={TextField}
          control={control}
          name="remuneracao"
          label="Remuneracao"
          style={{ width: '300px' }}
        />
      </div>
      <div style={{ padding: '20px' }}>
        <GridAgenteCDC />
      </div>
    </TemplateScreen>
  );
};

export default AgenteCDC;
