import styled from 'styled-components';

/* Components */

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.text.colors.dark};
`;

export const WrapperInput = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 22%;
  left: 0.5rem;
  color: ${({ theme }) => theme.semantic.secondary};
`;

export const Input = styled.input`
  display: block;
  padding: 0.375rem 2rem;
  width: 100%;
  padding-right: 1em;
  background-color: ${({ theme }) => theme.semantic.light};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.semantic.dark};
  border: 0;
  border: ${({ theme }) => `thin solid ${theme.text.colors.muted}`};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    outline: 0;
  }

  &::placeholder {
    opacity: 1;
  }
`;

export const InvalidFeedback = styled.span`
  width: 100%;
  font-size: 11px;
  color: ${({ theme }) => theme.text.colors.danger};
  font-style: italic;
`;
