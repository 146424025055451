import React, { useCallback, useState, useEffect } from 'react';

import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

type OptionValue = {
  label: string;
  value: string | number;
};

interface InputSelectAsyncProps {
  loadOptions: (query: string) => void;
  handleOnChange?: any;
  onChange?: any;
  fullWidth?: boolean;
  loading?: boolean;
  options: OptionValue[];
  label: string;
  error?: boolean;
  errorMessage?: string;
  margin?: 'dense' | 'none' | 'normal';
  className?: string;
}

const InputSelectAsync: React.FC<InputSelectAsyncProps> = ({
  loadOptions,
  handleOnChange,
  onChange,
  options,
  fullWidth = false,
  loading,
  label,
  error,
  errorMessage,
  margin = 'normal',
  className,
  ...rest
}) => {
  const [timer, setTimer] = useState<number | null>(null);

  const handleInputChange = useCallback(
    (_, value: string) => {
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        loadOptions(value);
      }, 1000);
      setTimer(newTimer);
    },
    [timer, loadOptions],
  );

  const handleChange = useCallback(
    (_, value) => {
      if (handleOnChange !== undefined) {
        handleOnChange(value);
      }
      onChange(value);
    },
    [handleOnChange, onChange],
  );

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      {...rest}
      fullWidth={fullWidth}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      options={options}
      loading={loading}
      className={className}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin={margin}
          error={error}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default InputSelectAsync;
