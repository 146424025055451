import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import {
  Container,
  Label,
  WrapperInput,
  Input,
  IconWrapper,
  InvalidFeedback,
} from './styles';

import Icon from '../../icons';

export interface InputProps {
  name: string;
  defaultValue?: string | number;

  label?: string;
  placeholder?: string;

  error?: FieldError;

  width?: string;
  height?: string;
  type?: 'text' | 'password' | 'number';
  icon: string;
}

type Ref = HTMLInputElement;

const InputIcon = forwardRef<Ref, InputProps>(
  (
    {
      label = '',
      name,
      placeholder = '',
      error,
      width,
      height,
      type = 'text',
      icon,
      defaultValue,
    },
    ref,
  ) => (
    <Container style={{ width, height }}>
      <Label>{label}</Label>

      <WrapperInput>
        <IconWrapper>
          <Icon icon={icon} />
        </IconWrapper>
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          ref={ref}
        />
      </WrapperInput>
      {error?.message && <InvalidFeedback>{error.message}</InvalidFeedback>}
    </Container>
  ),
);

export default React.memo(InputIcon);
