import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, FormControl } from '@material-ui/core';

type OptionValue = {
  label: string;
  value: string | number;
};

interface InputSelectSearchProps {
  label: string;
  name: string;
  value?: string | number;
  options: OptionValue[];
  helperText?: string;
  error?: boolean;
  className?: string;
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
  placeholder?: string;
  onChange?: (value: string | number | null) => void;
}

const InputSelectSearch: React.FC<InputSelectSearchProps> = ({
  label,
  options,
  value,
  name,
  helperText,
  error,
  className,
  margin,
  fullWidth = true,
  placeholder,
  onChange,
  ...rest
}) => {
  const selectedOption =
    options.find((option) => option.value === value) || null;

  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    newValue: OptionValue | null,
  ) => {
    if (newValue) {
      // eslint-disable-next-line no-unused-expressions
      onChange?.(newValue.value);
    } else {
      // eslint-disable-next-line no-unused-expressions
      onChange?.(null);
    }
  };

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      error={error}
      className={className}
      variant="outlined"
    >
      <Autocomplete
        {...rest}
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedOption}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            variant="standard"
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            name={name}
            placeholder={placeholder}
          />
        )}
      />
    </FormControl>
  );
};

export default InputSelectSearch;
